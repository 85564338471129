import React from "react";
import "./Message.css";
import { Avatar } from "@material-ui/core";

function Message({ timestamp, user, message }) {
  return (
    <div className="message">
      <Avatar src={user.photo} />
      <div className="message__info">
        <h4>
          {user.displayName}
          <span className="message__timeStamp">
            {new Date(timestamp?.toDate()).toUTCString()}
          </span>
        </h4>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default Message;
// Deploy Instructions - https://youtu.be/zc1loX80TX8?t=9803
